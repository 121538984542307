
export const quiz9 = {
	missionId: 30,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 31
	},
	questionTxt:
		'Um associado vai até o Sicredi e, sem autorização, distribui canetas e adesivos com o logotipo de um partido político para as pessoas colaboradoras, sugerindo que todos deveriam apoiar esse partido, o que você faz?',
	alternatives: [
		{
			id: 1,
			text: 'A - Discretamente, agradece o associado pela gentileza, decide não intervir, permitindo que a propaganda política continue a ocorrer dentro do Sicredi.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Agradeço ao associado e aceito as canetas e adesivos do associado e, em seguida, ajuda a distribuir esses materiais aos colegas de trabalho, pois não podemos gerar desconforto com o associado.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Discretamente, agradece o associado pela gentileza, mas comenta que não é permitido fazer campanhas eleitorais dentro da estrutura do Sicredi, pois a instituição mantém a neutralidade político-partidária.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 4,
			text: 'D - Chamo a atenção do associado explicando que não é permitido fazer campanhas eleitorais dentro da estrutura do Sicredi, pois isso vai contra o código de ética.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 0,
			correct: false

		},
	],
};
