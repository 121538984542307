import styles from './escape13.module.scss';

export const escapeLive13 = {
	missionId: 43,
	bgImage: '/escape/scenario13/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario13/escape1.png',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario13/escape2.png',
			},
			className: `${styles.scenario_image_2} ${styles.locks}`,
		},
		{
			lockId: 'scenario_13_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_13_lock_1',
				redirectTo: null,
				type: 'lock',
				password: '8275',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario13/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario13/reward_1.png',
			},
		},
		{
			lockId: 'scenario_13_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_13_lock_2',
				type: 'lock',
				missionId: 43,
				redirectTo: {
					type: 'game',
					missionId: 44,
				},
				password: '80394812',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario13/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 44,
	},
};
