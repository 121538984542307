/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import styles from './quiz.module.scss';
import { quiz1 } from './Quizes/Quiz1';
import { quiz2 } from './Quizes/Quiz2';
import { isMobile } from 'react-device-detect';
import { getApiClient } from 'src/services/axios';
import { useParams } from 'react-router-dom';
import showToast from 'src/components/utils/toast';
import GenericModal from 'src/components/Generics/Modal/Modal';
import ModalContentQuiz from './ModalContent';
import { useQuery } from 'src/hooks/queryClient';
import { quiz3 } from './Quizes/Quiz3';
import { quiz4 } from './Quizes/Quiz4';
import ReactLoading from 'react-loading';
import { quiz5 } from './Quizes/Quiz5'
import { quiz6 } from './Quizes/Quiz6'
import { quiz7 } from './Quizes/Quiz7'
import { quiz8 } from './Quizes/Quiz8'
import { quiz9 } from './Quizes/Quiz9'
import { quiz10 } from './Quizes/Quiz10'
import { quiz11 } from './Quizes/Quiz11';
import { quiz12 } from './Quizes/Quiz12';

const quizList = {
	// semana 1
	2: quiz1,
	4: quiz2,
	// semana 3
	9: quiz3,
	11: quiz4,
	// semana 5
	16: quiz5,
	18: quiz6,
	// semana 7
	23: quiz7, 
	25: quiz8,
	// semana 9
	30: quiz9,
	32: quiz10,
	// semana 11
	37: quiz11,
	39: quiz12
};

export default function Quiz({ missionId, setPage }) {
	const [selectedAlternative, setSelectedAlternative] = useState(null);
	const [isOpenGenericModal, setIsOpenGenericModal] = useState(false);
	const [correctAlternative, setCorrectAlternative] = useState(false);
	const quiz = quizList[missionId];
	const { playerId } = useParams();
	const token = localStorage.getItem('SUT');	



	async function handleSubmit() {
		if (!selectedAlternative) {
			showToast({ text: 'Selecione uma alternativa', type: 'error' });
			return;
		}

		if (selectedAlternative.redirectTo) {
			try {
				const token = localStorage.getItem('SUT');

				await getApiClient(token).post(`/updateReport/${playerId}`, {
					missions: [quiz.missionId],
					choices: [
						{
							missionId: quiz.missionId,
							choice: selectedAlternative.text,
							score: selectedAlternative.score,
						},
					],
					selectOptionsChoices: [],
				});

				setCorrectAlternative(selectedAlternative.correct);

				setIsOpenGenericModal(true);
			} catch (error) {
				showToast({
					text: 'Ocorreu um erro ao tentar salvar a questão, favor reportar.',
					type: 'error',
				});
			}
		}
	}

	function handleNextPageModal() {
		const redirect = quiz.redirectTo;
		setPage(redirect.type, redirect.missionId);
	}
	const {
		data: userReport,
		status,
		isLoadingError,
		error,
	} = useQuery({
		url: `/getReport/${playerId}`,
		key: 'playerReportOnQuiz',
		enabled: !!token,
		token: token,
	});

	// mission id dessa missao e 1
	useEffect(() => {
		
		if (
			status === 'success' &&
			userReport.missions.includes(quiz.missionId)
		) {
			setTimeout(() => {
				const redirect = quiz.redirectTo;
				setPage(redirect.type, redirect.missionId);
			}, 0);
		}
	}, [status]);

	return status === 'success' ? (
		<div className={`${styles.quiz} ${isMobile && styles.mobile}`}>
			<GenericModal
				closeBtnDisabled
				isOpen={isOpenGenericModal}
				setIsOpen={setIsOpenGenericModal}
				onClick={() => handleNextPageModal()}
				content={{
					modalContent: (
						<ModalContentQuiz
							quiz={quiz}
							correctAlternative={correctAlternative}
						/>
					),
				}}
			/>
			<div className={styles.quizBody}>
				<div className={styles.header}>
					<img src={quiz.img} />
				</div>
				<div className={styles.question}>
					{quiz.auxImg && <img src={quiz.auxImg} />}
					<span>{quiz.questionTxt}</span>
				</div>
				<div className={styles.alternativesWrapper}>
					<div className={styles.alternatives}>
						{quiz.alternatives.map((alternative) => (
							<button
								onClick={() =>
									setSelectedAlternative(alternative)
								}
								className={`${styles.alternative} ${
									selectedAlternative &&
									selectedAlternative.id === alternative.id
										? styles.selected
										: ''
								}`}
								key={`alternative_${alternative.id}`}
							>
								{alternative.text}
							</button>
						))}
					</div>
					<button
						onClick={() => handleSubmit()}
						className={styles.btn}
					>
						Enviar
					</button>
				</div>
			</div>
		</div>
	) : (
		<div className={styles.loading}>
			<ReactLoading
				type={'spin'}
				height={'100%'}
				className={styles.spin}
				color={'white'}
			/>
		</div>
	);
}
