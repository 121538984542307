import { useEffect, useState } from 'react';
import styles from './login.module.scss';
import { getApiClient } from '../../services/axios';
import showToast from '../utils/toast';
import { jwtDecode } from 'jwt-decode';
import { isMobile } from 'react-device-detect';
import { Eye, EyeClosedOutline } from '../Signup/icons';
import ForgotPassModal from './Modal';

export default function Login() {
	const [openModal, setOpenModal] = useState(false);
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});
	const [visiblePassword, setVisiblePassword] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await getApiClient().post('/login', {
				email: formData.email,
				password: formData.password,
			});
			if (response.status === 200) {
				localStorage.setItem('SUT', response.data.token);

				const user = jwtDecode(response.data.token);
				showToast({
					text: 'Login realizado com sucesso ',
					type: 'success',
				});
				setTimeout(() => {
					window.location.href = `/${user.id}`;
				}, 2000);
				return;
			}
			showToast({
				text: 'Usuário ou senha inválidos',
				type: 'error',
			});
		} catch (error) {
			if (error.response.data.message === 'User is not active') {
				showToast({
					text: 'Usuário não está ativo, verifique a caixa de entrada do seu email para ativar seu usuário',
					type: 'error',
				});
				return
			}
			showToast({ text: 'Usuário ou senha inválidos', type: 'error' });
		}
	};

	useEffect(() => {
		const token = localStorage.getItem('SUT');
		if (token) {
			const user = jwtDecode(token);

			window.location.href = `/${user.id}`;
		}
	}, []);

	return (
		<div className={`${styles.container} ${isMobile && styles.mobile}`}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img src="/reinodaetica/reinodaetica.png" />

				<label htmlFor="email" className={styles.label}>
					E-mail corporativo (cooperativa):
				</label>
				<input
					type="email"
					id="email"
					name="email"
					className={styles.input}
					value={formData.email}
					onChange={handleChange}
				/>

				<label htmlFor="password" className={styles.label}>
					Senha:
				</label>
				<div className={styles.passwordWrapper}>
					<input
						required
						type={visiblePassword ? 'text' : 'password'}
						id="password"
						name="password"
						className={styles.input}
						value={formData.password}
						onChange={handleChange}
					/>
					{visiblePassword ? (
						<EyeClosedOutline
							onClick={() => setVisiblePassword(false)}
							className={styles.icon}
						/>
					) : (
						<Eye
							onClick={() => setVisiblePassword(true)}
							className={styles.icon}
						/>
					)}
				</div>

				<button type="submit" className={styles.button}>
					Entrar
				</button>
			</form>
			<ForgotPassModal isOpen={openModal} setIsOpen={setOpenModal} />
			<span
				onClick={() => setOpenModal(true)}
				className={styles.forgotPass}
			>
				{' '}
				Esqueceu a senha ?
			</span>
		</div>
	);
}
