
export const quiz10 = {
	missionId: 32,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 33
	},
	questionTxt:
		'Um amigo de infância se candidata nas eleições e pede um favor a você, para que envie mensagens em massa com links de vídeos de propaganda política aos colegas de trabalho do Sicredi. Qual seria sua atitude?',
	alternatives: [
		{
			id: 1,
			text: 'A - Você compartilha os links por grupos de WhatsApp particular onde esta seus colegas do Sicredi, pois é uso particular.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Não aceito o pedido pois as ferramentas de comunicação do Sicredi são de uso interno. Eu compartilho pelas redes sociais que são de uso pessoal.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Não aceito o pedido, pois é proibido o uso das ferramentas de comunicação para uso politico, podendo ate ter uma punição para a pessoa colaboradora.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D - Você não aceita o pedido e comenta que, como pessoa colaboradora do Sicredi, as ferramentas de comunicação da empresa não podem ser utilizadas para fins não corporativos. Além disso, você destaca que não é adequado encaminhar propagandas político-partidárias para colegas de trabalho.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 100,
			correct: true

		},
	],
};
