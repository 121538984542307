export const steps = [
	// semana 1
	{
		missionId: 1,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 5,
		label: 'Semana 1',
		unlocked: true,
		img: '/steps_images/step_week1.png',
		preGame: {
			title: 'Conduta com a imagem do Sicredi fora do ambiente de trabalho',
			text: 'Nesta aventura você será exposto `à situações que servem para reforçar a importância de manter a neutralidade política e não associar a marca Sicredi a posicionamentos político-partidários em ambientes públicos e virtuais.',
			image: '/weeksImgs/week1/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 2,
		hidden: true,
		finishedMissionId: 5,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 1',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 3,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 5,
		label: 'Semana 1',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 4,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 5,
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 5,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 5,
		videoUrl: 'https://vimeo.com/1001243343/b741aebf0a?share=copy',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	/// semana 2
	{
		missionId: 6,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 7,
		label: 'Semana 2',
		unlocked: true,
		img: '/steps_images/step_week2.png',
		preGame: {
			title: 'Postura profissional e cuidado com a nossa marca e imagem',
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/weeksImgs/week2/preGaming.png',
		},
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema. ',
		videoUrl: 'https://vimeo.com/1002069396/98ecfd163f?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 7,
		},
	},
	{
		missionId: 7,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 7,
		label: 'Semana 2',
		unlocked: true,
		img: '/escape/scenario1/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/weeksImgs/week2/preGaming.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	/// semana 3
	{
		missionId: 8,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 12,
		label: 'Semana 3',
		unlocked: true,
		img: '/steps_images/step_week3.png',
		preGame: {
			title: 'Conduta de confidencialidade de informações financeiras',
			text: 'Nesta etapa vamos encarar situações que destacam a importância de manter a integridade e a confiança, essenciais no setor financeiro, especialmente no que diz respeito à proteção de dados e ao sigilo bancário.',
			image: '/weeksImgs/week3/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 9,
		hidden: true,
		finishedMissionId: 12,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 3',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 10,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 12,
		label: 'Semana 3',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 11,
		hidden: true,
		finishedMissionId: 12,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 3',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 12,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 12,
		videoUrl: 'https://vimeo.com/1005476458/7657b51051?share=copy',
		label: 'Semana 3',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	/// semana 4
	{
		missionId: 13,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 14,
		label: 'Semana 4',
		unlocked: true,
		img: '/steps_images/step_week4.png',
		preGame: {
			title: 'Postura de confidencialidade.',
			text: 'Nesta etapa você irá identificar a importância da confidencialidade no ambiente de trabalho. Através de três dilemas práticos, você aprenderá como proteger informações sensíveis e seguir as condutas esperadas para manter a integridade e a confiança de nossos associados.',
			image: '/weeksImgs/week4/preGaming.png',
		},
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema.',
		videoUrl: 'https://vimeo.com/1006949059/c0bdd4d81a?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 14,
		},
	},
	{
		missionId: 14,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 14,
		label: 'Semana 2',
		unlocked: true,
		img: '/escape/scenario3/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/Week4/preGaming.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	// semana 5
	{
		missionId: 15,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 19,
		label: 'Semana 5',
		unlocked: true,
		img: '/steps_images/step_week5.png',
        preGame: {
			title: 'Ética entre pessoas colaboradoras e figura pública',
			text: 'Nesta missão, iremos ver situações que destacam a importância de manter a integridade e a ética nas interações entre pessoas colaboradoras e figuras políticas, garantindo que todas as ações estejam em conformidade com os princípios e valores do Sicredi.',
			image: '/weeksImgs/week5/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 16,
		hidden: true,
		finishedMissionId: 19,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 5',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 17,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 19,
		label: 'Semana 5',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 18,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 19,
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 19,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 19,
		videoUrl: 'https://vimeo.com/1010211099/80c9dfbf2b?share=copy',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	// semana 6
	{
		missionId: 20,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 21,
		label: 'Semana 6',
		unlocked: true,
		img: '/steps_images/step_week6.png',
		preGame: {
			title: 'Conflito de interesses',
			text: 'Esta etapa abordaremos a importância da confidencialidade e conduta ética no ambiente de trabalho através de dilemas práticos. Destacaremos como manter a imparcialidade, transparência e integridade nas relações com fornecedores, processos seletivos e interações com associados.',
			image: '/weeksImgs/week6/preGaming.png',
		},
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema. ',
		videoUrl: 'https://vimeo.com/1012770024/1152b81d1d?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 21,
		},
	},
	{
		missionId: 21,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 21,
		label: 'Semana 2',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/Week1/preGame/img.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},
	
	// semana 7
	{
		missionId: 22,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 26,
		label: 'Semana 7',
		unlocked: true,
		img: '/steps_images/step_week7.png',
        preGame: {
			title: 'Ambiente de trabalho respeitoso e neutro',
			text: 'Destacaremos a importância de manter um ambiente de trabalho neutro e respeitoso, evitando conflitos desnecessários e mantendo o foco na missão e valores do Sicredi.',
			image: '/weeksImgs/week7/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 23,
		hidden: true,
		finishedMissionId: 26,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 5',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 24,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 26,
		label: 'Semana 5',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 25,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 26,
		label: 'Semana 7',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 26,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 26,
		videoUrl: 'https://vimeo.com/1014810288/9d3cfcc29d?share=copy',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	// semana 8
	{
		missionId: 27,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 28,
		label: 'Semana 8',
		unlocked: true,
		img: '/steps_images/step_week8.png',
		preGame: {
			title: 'Conversas difíceis',
			text: 'Esta etapa abordaremos como lidar em situações difíceis no ambiente de trabalho. Destacaremos como agir em certas situações, mantendo a integridade nas relações com fornecedores, processos seletivos e interações com associados.',
			image: '/weeksImgs/week8/preGaming.png',
		}, 
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema. ',
		videoUrl: 'https://vimeo.com/1017534541/3710d9fb3d?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 28,
		},
	},
	{
		missionId: 28,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 28,
		label: 'Semana 2',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/Week1/preGame/img.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},


	// semana 9
	{
		missionId: 29,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 33,
		label: 'Semana 9',
		unlocked: false,
		img: '/steps_images/step_week9.png',
        preGame: {
			title: 'Ambiente de trabalho respeitoso e neutro',
			text: 'Destacaremos a importância de manter um ambiente de trabalho neutro e respeitoso, evitando conflitos desnecessários e mantendo o foco na missão e valores do Sicredi.',
			image: '/weeksImgs/week9/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 30,
		hidden: true,
		finishedMissionId: 33,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 9',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 31,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 33,
		label: 'Semana 5',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 32,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 33,
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 33,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 33,
		videoUrl: 'https://vimeo.com/1002069396/98ecfd163f?share=copy',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	// semana 10
	{
		missionId: 34,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 35,
		label: 'Semana 10',
		unlocked: false,
		img: '/steps_images/step_week10.png',
		preGame: {
			title: 'Conflito de interesses',
			text: 'Esta etapa abordaremos a importância da confidencialidade e conduta ética no ambiente de trabalho através de dilemas práticos. Destacaremos como manter a imparcialidade, transparência e integridade nas relações com fornecedores, processos seletivos e interações com associados.',
			image: '/weeksImgs/week10/preGaming.png',
		},
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema. ',
		videoUrl: 'https://vimeo.com/1002069396/98ecfd163f?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 35,
		},
	},
	{
		missionId: 35,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 35,
		label: 'Semana 2',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/Week1/preGame/img.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},


	// semana 11
	{
		missionId: 36,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 40,
		label: 'Semana 11',
		unlocked: false,
		img: '/steps_images/step_week11.png',
        preGame: {
			title: 'Importância da neutralidade política no ambiente de trabalho',
			text: 'Essas situações servem para reforçar a importância da neutralidade política no ambiente de trabalho e garantir que as atividades das pessoas colaboradoras estejam alinhadas com os valores e políticas do Sicredi.',
			image: '/weeksImgs/week11/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 37,
		hidden: true,
		finishedMissionId: 40,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 11',
		unlocked: false,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 38,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 40,
		label: 'Semana 11',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 39,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 40,
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 40,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 40,
		videoUrl: 'https://vimeo.com/1002069396/98ecfd163f?share=copy',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},

	
	// semana 12
	{
		missionId: 41,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 42,
		label: 'Semana 12',
		unlocked: false,
		img: '/steps_images/step_week12.png',
		preGame: {
			title: 'Saúde financeira',
			text: 'Esta etapa abordaremos a importância da confidencialidade e conduta ética no ambiente de trabalho através de dilemas práticos. Destacaremos como manter a imparcialidade, transparência e integridade nas relações com fornecedores, processos seletivos e interações com associados.',
			image: '/weeksImgs/week10/preGaming.png',
		},
		ignoreForShield: true,

		modalContent:
			'Agora você irá ver um vídeo, ilustrando alguns dilemas que você pode encontrar na sua rotina. Faça uma reflexão sobre eles antes de seguir com a resposta daquele dilema. ',
		videoUrl: 'https://vimeo.com/1002069396/98ecfd163f?share=copy',
		redirectTo: {
			type: 'game',
			missionId: 42,
		},
	},
	{
		missionId: 42,
		type: 'selectOptions',
		pageType: 'selectOptions',
		finishedMissionId: 42,
		label: 'Semana 12',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
		preGame: {
			text: 'Nesta aventura você será exposto `à condutas esperadas e não esperadas sobre o cuidado que você deve ter com relação à marca e imagem da Sicredi. ',
			image: '/Week1/preGame/img.png',
		},
		hidden: true,
		modalContent:
			'E aí, o que você achou dos dilemas apresentados? Agora vamos reforçar os pontos da nossa política de condutas esparadas X condutas não esperados e ver como você se sai. ',
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},



// semana 13
	{
		missionId: 43,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 47,
		label: 'Semana 13',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
        preGame: {
			title: 'Ética entre colaboradores e figura pública',
			text: 'Nesta missão, iremos ver situações que destacam a importância de manter a integridade e a ética nas interações entre pessoas colaboradoras e figuras políticas, garantindo que todas as ações estejam em conformidade com os princípios e valores do Sicredi.',
			image: '/weeksImgs/week5/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 44,
		hidden: true,
		finishedMissionId: 47,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 5',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 45,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 47,
		label: 'Semana 5',
		unlocked: true,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 46,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 47,
		label: 'Semana 1',
		unlocked: true,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 47,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 47,
		videoUrl: 'fK4rj6Ya75I',
		label: 'Semana 1',
		unlocked: true,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},
    
    //semama 14
    {
		missionId: 48,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 52,
		label: 'Semana 14',
		unlocked: false,
		img: '/escape/scenario1/bg.png',
        preGame: {
			title: 'Ética entre colaboradores e figura pública',
			text: 'Nesta missão, iremos ver situações que destacam a importância de manter a integridade e a ética nas interações entre colaboradores e figuras políticas, garantindo que todas as ações estejam em conformidade com os princípios e valores do Sicredi.',
			image: '/weeksImgs/week5/preGaming.png',
		},
		modalContent:
			'Agora você irá entrar em um desafio, estilo escape game. Fique atento(a) às pistas e use as informações para abrir cadeados. Você tem um tempo limite para completar esta atividade (6min) e ganhará pontos por cada segundo extra que sobrar no seu tempo. Uma vez que você começar a atividade, o tempo irá correr, mesmo que você saia do jogo, por isso se concentre e uma vez iniciado o jogo, foque somente nele. Bom jogo. ',
		ignoreForShield: true,
	},
	{
		missionId: 49,
		hidden: true,
		finishedMissionId: 52,
		pageType: 'game',
		type: 'quiz',
		label: 'Semana 5',
		unlocked: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 50,
		type: 'escape',
		pageType: 'game',
		finishedMissionId: 52,
		label: 'Semana 5',
		unlocked: false,
		hidden: true,
		img: '',
		ignoreForShield: true,
	},
	{
		missionId: 51,
		type: 'quiz',
		pageType: 'game',
		finishedMissionId: 52,
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: true,
	},
	{
		missionId: 52,
		type: 'video',
		pageType: 'video',
		finishedMissionId: 52,
		videoUrl: 'fK4rj6Ya75I',
		label: 'Semana 1',
		unlocked: false,
		img: '',
		hidden: true,
		ignoreForShield: false,
		redirectTo: {
			type: 'complaints',
			missionId: 1,
		},
	},


];
