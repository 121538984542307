export const clues = {
    // semana 1 
    'scenario_1_lock_1': 'Observe as cores das gavetas pela sala.',
    'scenario_1_lock_2': 'Duas condutas estão erradas, e as outras 5 irão gerar o código para o cadeado.',
    'scenario_1_lock_3': 'Observe o crachá de acesso e siga a ordem das setas par definir a senha.',

    'scenario_2_lock_1': 'Duas condutas estão erradas e o número das outras 3 irão formar a senha para abrir a mochila.',
    'scenario_2_lock_2': 'Observe os blocos colorido na mesa e siga a linha desenhada.',
  
    //semana 3

    'scenario_3_lock_1': 'Escolha a melhor resposta para a mensagem ao lado e veja os números para definir a senha.',
    'scenario_3_lock_2': 'Siga a historia nos quadros para ordenar os números da senha.',
    'scenario_3_lock_3': 'Cada forma tem um número relacionado, descubras os números e responda a equação para definir a senha.',

    
    'scenario_4_lock_1': 'Observe as pastas coloridas no computador e organize a resposta de acordo com suas cores e seus números para criar a senha.',
    'scenario_4_lock_2': 'Verifique a matriz no Quadro de Condutas e relacione com os números na matriz ao lado, descubra quais as condutas corretas para definir a senha.',


    //semana 5

    'scenario_5_lock_1': 'Verifique os quadros na sala com os números e formas e relacione com escultura, seguindo a ordem que aparece nela para achar a senha.',
    'scenario_5_lock_2': 'Veja o mapa na TV, para cada parada do ônibus, é revelado um número, siga o itinerário para revelar a senha.',
    'scenario_5_lock_3': 'Identifique na lista de valores do Sicredi qual o valor que encaixa na quantidade de letras que aparece na porta.',

    'scenario_6_lock_1': 'Verifique o papel colado na parede. Veja o campos coloridos e coloque suas respectivas letras para descobrir a senha.',
    'scenario_6_lock_2': 'Veja no recibo os itens comprados e some o valor deles para descobrir a senha.',

    //semana 7

    'scenario_7_lock_1': 'Veja as luzes que estão pela sala e acenda de acordo com as suas cores.',
    'scenario_7_lock_2': 'Verifique no computador a coluna da esquerda com a coluna da direita e relacione as colunas, veja o conteúdo no Ciclo Estratégico 2025.',
    'scenario_7_lock_3': 'Veja a forma que aparece na porta de vidro e veja ela separada para descobrir cada elemento.',

    'scenario_8_lock_1': 'Descubra a senha do cadeado decifrando a palavra que esta como  anagrama na placa.',
    'scenario_8_lock_2': 'Relacione a matriz dos números com os jogos da velha, observe a jogada do circulo para descobrir a senha.',

    //semana 9

    'scenario_9_lock_1': 'Verifique na imagem do Ciclo Estratégico 2025 as ambições definidas para identificar a senha.',
    'scenario_9_lock_2': 'Compare as cores que consta no papel com o cartaz dos preços dos carros para achar a senha.',

    'scenario_10_lock_1': 'Identifique pelo ambiente a sequencia das luzes em cada coluna para achar a senha.',
    'scenario_10_lock_2': 'A imagem da matriz define a senha e as cores abaixo a ordem, compare a matriz com a imagem da maquiagem.',
    'scenario_10_lock_3': 'Veja na imagem que contem as fotos, 2 delas possuem condutas não esperadas dentro do ambiente de trabalho.',

    //semana 11

    'scenario_11_lock_1': 'Identifique pelo ambiente a sequencia das luzes para definir o que esta ligado e desligado.',
    'scenario_11_lock_2': 'Identifique na matriz da parede preta as condutas corretas e compare suas posição com a matriz no café na ordem crescente.',

    'scenario_12_lock_1': 'Compare o movimento que possui na matriz com o cambio da marca para achar a senha como segue o exemplo: A2B1...',
    'scenario_12_lock_2': 'Você pode encontrar a resposta da charada no Ciclo Estratégico 2025 ou resolver o anagrama.',

    //semana 13
    
    'scenario_13_lock_1': 'Coloque os potes na ordem crescente do risco no rotulo, após isso cada pote tem um numero da senha, na ordem dos postes ja definidos.',
    'scenario_13_lock_2': 'Identifique quais são os pilares do Sicredi no Ciclo Estratégico 2025 e compare as formas no computador para achar a senha.',

    'scenario_14_lock_1': 'Identifique no livro as duas condutas incorretas no Sicredi para definir a senha em ordem crescente.',
    'scenario_14_lock_2': 'A imagem no quadro a forma identifica a coluna na matriz e o numero é a linha da matiz que esta na TV. A senha é a ordem encontrada das orientações no quadro'
} 