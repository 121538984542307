import { useState } from 'react';
import styles from './recovery-password.module.scss';
import { getApiClient } from 'src/services/axios';
import showToast from 'src/components/utils/toast';
import PasswordStrengthBar from 'react-password-strength-bar';
import { isMobile } from 'react-device-detect';
import { Eye, EyeClosedOutline } from 'src/components/Signup/icons';
import { jwtDecode } from 'jwt-decode';
import { useParams } from 'react-router-dom';

export default function RecoveryPassword() {
	const scoreWords = ['Fraca', 'Ok', 'Boa', 'Excelente'];
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
	});
	const { token } = useParams();
	const [visiblePassword, setVisiblePassword] = useState(false);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (formData.password !== formData.confirmPassword) {
			showToast({
				text: 'Senha e confirmação de senha devem ser iguais',
				type: 'error',
			});
		}

		let text = 'Atualização efetuada com sucesso, você será logado';
		try {
			await getApiClient(token).post('/reset-password', {
				email: formData.email,
				password: formData.password,
			});

			const response = await getApiClient().post('/login', {
				email: formData.email,
				password: formData.password,
			});

			localStorage.setItem('SUT', response.data.token);

			const user = jwtDecode(response.data.token);

			showToast({ text: text, type: 'success' });
			setTimeout(() => {
				window.location.href = `/${user.id}`;
			}, 2000);
			return;
		} catch (error) {
			text = 'Erro ao atualizar a senha';
			showToast({ text: text, type: 'error' });
		}
	};

	return (
		<div className={`${styles.container} ${isMobile && styles.mobile}`}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img src="/sicred/sicred_logo.png" />

				<h2>Recuperação de senha</h2>
				<label htmlFor="email" className={styles.label}>
					E-mail corporativo  (cooperativa):
				</label>
				<input
					required
					type="email"
					id="email"
					name="email"
					className={`${styles.input} ${styles.positionAbsolute}`}
					value={formData.email}
					onChange={handleChange}
				/>

				<label htmlFor="password" className={styles.label}>
					Senha:
				</label>
				<div className={styles.passwordWrapper}>
					<input
						required
						type={visiblePassword ? 'text' : 'password'}
						id="password"
						name="password"
						className={styles.input}
						value={formData.password}
						onChange={handleChange}
					/>
					{visiblePassword ? (
						<EyeClosedOutline
							onClick={() => setVisiblePassword(false)}
							className={styles.icon}
						/>
					) : (
						<Eye
							onClick={() => setVisiblePassword(true)}
							className={styles.icon}
						/>
					)}
				</div>
				<PasswordStrengthBar
					className={styles.passwordBar}
					shortScoreWord={'Muito fraca'}
					scoreWords={scoreWords}
					password={formData.password}
				/>
				<label htmlFor="confirmPassword" className={styles.label}>
	 				Confirmação de Senha:
				</label>
				<input
					required
					type={visiblePassword ? 'text' : 'password'}
					id="confirmPassword"
					name="confirmPassword"
					className={styles.input}
					value={formData.confirmPassword}
					onChange={handleChange}
				/>
				<button type="submit" className={styles.button}>
					Atualizar senha
				</button>
			</form>
		</div>
	);
}
