import styles from './escape11.module.scss';

export const escapeLive11 = {
	missionId: 36,
	bgImage: '/escape/scenario11/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario11/escape1.png',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},

		{
			lockId: 'scenario_11_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_11_lock_1',
				redirectTo: null,
				type: 'switch',
				customSwitchStyle: styles.switch,
				switchs: [
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: true,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: true,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: false,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: true,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: true,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
					{
						header: <div className={styles.text}>ON</div>,
						footer: <div className={styles.text}>OFF</div>,
						on: false,
						customOnSwitchColor: '#FFF100',
						switchStyle: styles.switchStyle,
					},
				],
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario11/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario11/reward_1.png',
			},
		},
		{
			lockId: 'scenario_11_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_11_lock_2',
				type: 'lock',
				missionId: 36,
				redirectTo: {
					type: 'game',
					missionId: 37,
				},
				password: '3578',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario11/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 37,
	},
};
