export const selectOptions5 = {
	missionId: 35,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 5,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	},
	options: [
        {
            id: 'selectOption5_1',
            text: 'Buscar conhecimento sobre diversidade e inclusão (D&l), e estimular que seus colegas também o façam.',
            score: 50
        },
        {
            id: 'selectOption5_2',
            text: 'Contratar pessoas buscando ter diversidade no time, e promover a inclusão de todos.',
            score: 50
        },
        {
            id: 'selectOption5_3',
            text: 'Fazer piadas ou comentários, mesmo que seja apenas um elogio, sobre o corpo, a etnia, a orientação sexual, a religião, idade ou deficiência de um colega, ou de qualquer pessoa.',
            score: 0
        },
        {
            id: 'selectOption5_4',
            text: 'Ser empático e respeitoso com colegas, associados, fornecedores e parceiros, independente de raça, etnia, orientação sexual, gênero, deficiência, religião, idade. ',
            score: 50
        },
        {
            id: 'selectOption5_5',
            text: 'Discutir ou agredir fisicamente qualquer pessoa dentro das dependências do Sicredi.',
            score: 0
        },
        {
            id: 'selectOption5_6',
            text: 'Usar expressões e palavras de conotação discriminatória.',
            score: 0
        },
        {
            id: 'selectOption5_7',
            text: 'Realizar piadas ou bullying com colegas e depois desculpar-se dizendo que foi só uma brincadeira.',
            score: 0
        },
        {
            id: 'selectOption5_8',
            text: 'Promover acessibilidade de arquitetura, social e tecnológica a todos os colegas que possuem deficiência.',
            score: 50
        },
        {
            id: 'selectOption5_9',
            text: 'Perseguir, abusar, expor ou coagir qualquer colega, provocando sentimentos e prejuízos morais, sexuais, ou de qualquer outra ordem..',
            score: 0
        },
        {
            id: 'selectOption5_10',
            text: 'Retaliar colaborador por realizar uma denúncia nos canais devidos, por ter identificado algum descumprimento ao código de conduta ou desobediência a CCT. ',
            score: 0
        },
        {
            id: 'selectOption5_11',
            text: 'Atentar para o bem estar dos colegas, observando se nenhum deles apresenta comportamentos que indique violência física ou psicológica, e convencê-los a buscar ajuda, como recorrer a uma delegacia ou a uma orientação psicológica.',
            score: 50
        },

    ]
};
